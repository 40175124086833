var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"press-release"},[_c('div',{staticClass:"press-release__wrapper"},[_c('div',{staticClass:"press-release__header-background",style:({
        backgroundImage: `url(${_vm.currentPressrelease.header_image})`,
      })}),_c('div',{staticClass:"press-release__main-container"},[_c('div',{staticClass:"press-release__details-box"},[_c('div',{staticClass:"press-release__details-box_tags"},_vm._l((_vm.currentPressrelease.tags),function(tag){return _c('Tag',{key:tag.id,staticClass:"tag--public",attrs:{"value":tag.title},on:{"click":function($event){return _vm.$router.push({
                path: '/content',
                query: { tag_id: tag.id },
              })}}})}),1),_c('h2',{staticClass:"press-release__details-box_title"},[_vm._v(" "+_vm._s(_vm.currentPressrelease.title)+" ")]),(_vm.currentPressrelease.date_published)?_c('span',{staticClass:"press-release__details-box_data"},[_vm._v(_vm._s(_vm.currentPressrelease.date_published)+" | by "+_vm._s(_vm.currentPressrelease.author)+" | "+_vm._s(_vm.currentPressrelease.read_time))]):_vm._e(),_c('div',{staticClass:"press-release__details-box_content collect-editor collect-editor--white",domProps:{"innerHTML":_vm._s(_vm.currentPressrelease.details)}}),(_vm.currentPressrelease.button_text && _vm.currentPressrelease.button_url)?_c('BaseButton',{staticClass:"button button--xs button--white",attrs:{"text":_vm.currentPressrelease.button_text},on:{"click":function($event){return _vm.goToLink(_vm.currentPressrelease.button_url)}}}):_vm._e()],1),(_vm.randomList)?_c('div',{staticClass:"press-release__title-box"},[_c('h2',{staticClass:"press-release__title-box_title"},[_vm._v("More Press Releases")])]):_vm._e(),(_vm.randomList)?_c('div',{staticClass:"press-release__press-releases-box"},_vm._l((_vm.randomList),function(item,key){return _c('router-link',{key:`${key}-item`,attrs:{"to":{ path: `/media/press-releases/${item.slug}` },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ navigate }){return [_c('div',{staticClass:"press-release__press-releases-box_card",on:{"click":navigate}},[_c('div',{staticClass:"image",style:({
                backgroundImage: `url(${item.search_image})`,
              })},[_c('div',{staticClass:"image__preview"},[_c('p',[_vm._v("Click to View")])])]),_c('div',{staticClass:"type"},[_vm._v("PRESS RELEASE")]),_c('h3',{staticClass:"title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('span',{staticClass:"data"},[_vm._v(_vm._s(item.date_published))])])]}}],null,true)})}),1):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }