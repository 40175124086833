<template>
  <div class="press-release">
    <div class="press-release__wrapper">
      <div
        class="press-release__header-background"
        :style="{
          backgroundImage: `url(${currentPressrelease.header_image})`,
        }"
      ></div>
      <div class="press-release__main-container">
        <div class="press-release__details-box">
          <div class="press-release__details-box_tags">
            <Tag
              class="tag--public"
              v-for="tag in currentPressrelease.tags"
              :key="tag.id"
              :value="tag.title"
              @click="
                $router.push({
                  path: '/content',
                  query: { tag_id: tag.id },
                })
              "
            />
          </div>
          <h2 class="press-release__details-box_title">
            {{ currentPressrelease.title }}
          </h2>
          <span v-if="currentPressrelease.date_published" class="press-release__details-box_data"
            >{{ currentPressrelease.date_published }} | by {{ currentPressrelease.author }} |
            {{ currentPressrelease.read_time }}</span
          >
          <div
            class="press-release__details-box_content collect-editor collect-editor--white"
            v-html="currentPressrelease.details"
          ></div>
          <BaseButton
            v-if="currentPressrelease.button_text && currentPressrelease.button_url"
            class="button button--xs button--white"
            :text="currentPressrelease.button_text"
            @click="goToLink(currentPressrelease.button_url)"
          />
        </div>

        <div v-if="randomList" class="press-release__title-box">
          <h2 class="press-release__title-box_title">More Press Releases</h2>
        </div>

        <div v-if="randomList" class="press-release__press-releases-box">
          <router-link
            v-for="(item, key) in randomList"
            :key="`${key}-item`"
            :to="{ path: `/media/press-releases/${item.slug}` }"
            v-slot="{ navigate }"
            custom
          >
            <div class="press-release__press-releases-box_card" @click="navigate">
              <div
                class="image"
                :style="{
                  backgroundImage: `url(${item.search_image})`,
                }"
              >
                <div class="image__preview"><p>Click to View</p></div>
              </div>
              <div class="type">PRESS RELEASE</div>
              <h3 class="title">
                {{ item.title }}
              </h3>
              <span class="data">{{ item.date_published }}</span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import BaseButton from "@/components/buttons/BaseButton.vue";
import Tag from "@/components/Tag.vue";

export default {
  components: { BaseButton, Tag },

  data() {
    return {};
  },
  head: {
    title: function () {
      return {
        inner: this.currentPressrelease.title ?? "Press Release browngrotta arts",
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content: `${this.currentPressrelease.title ?? "Press Release browngrotta arts"} - ${
            this.currentPressrelease.details ?? "Description Press Release browngrotta arts"
          }`,
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: this.currentPressrelease.title ?? "Press Release browngrotta arts",
          id: "meta:name",
        },
        {
          itemprop: "image",
          content:
            this.currentPressrelease.header_image ??
            `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: this.currentPressrelease.title ?? "Press Release browngrotta arts",
          id: "og:title",
        },
        {
          property: "og:image",
          content:
            this.currentPressrelease.header_image ??
            `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: this.currentPressrelease.title ?? "Press Release browngrotta arts",
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content: this.currentPressrelease.details ?? "Description Press Release browngrotta arts",
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: this.currentPressrelease.title ?? "Press Release browngrotta arts",
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content: this.currentPressrelease.details ?? "Description Press Release browngrotta arts",
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content:
            this.currentPressrelease.header_image ??
            `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: this.currentPressrelease.title ?? "Press Release browngrotta arts",
          id: "twitter:image:alt",
        },
      ];
    },
  },
  computed: {
    ...mapGetters("publicapi/pressreleases", {
      currentPressrelease: "getCurrentPressrelease",
      randomList: "getRandomPressreleasesList",
    }),
  },
  async created() {
    await this.apiGetSpecificPressrelease(this.$route.params.slug);
    this.$emit("updateHead");
    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Media",
        to: "/media",
        clickable: true,
      },
      {
        title: "Press Releases",
        to: "/media/press-releases",
        clickable: true,
      },
      {
        title: `${this.currentPressrelease.title}`,
        to: `/media/press-releases/${this.$route.params.slug}`,
        clickable: false,
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
  },

  watch: {
    "$route.path": async function () {
      await this.apiGetSpecificPressrelease(this.$route.params.slug);
      this.$emit("updateHead");
      const breadcrumbs = [
        {
          title: "Home",
          to: "/",
        },
        {
          title: "Media",
          to: "/media",
          clickable: true,
        },
        {
          title: "Press Releases",
          to: "/media/press-releases",
          clickable: true,
        },
        {
          title: `${this.currentPressrelease.title}`,
          to: `/media/press-releases/${this.$route.params.slug}`,
          clickable: false,
        },
      ];
      this.setBreadcrumbs(breadcrumbs);

      this.setShareData({
        title: this.currentPressrelease.title,
        description: this.currentPressrelease.details,
        image: this.currentPressrelease.header_image,
      });
    },
  },

  methods: {
    ...mapActions("publicapi/pressreleases", ["apiGetSpecificPressrelease"]),
    ...mapMutations("publicapi/share", ["setShareData"]),
    ...mapMutations(["setBreadcrumbs"]),

    goToLink(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  color: $white;
  font-size: 18px;
  line-height: 1.3;
  @media screen and (max-width: $xs) {
    font-size: 16px;
    line-height: 1.5;
  }
}

.press-release {
  display: flex;

  &__header {
    &-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 58.4rem;
      z-index: 0;
      background-size: auto 100%;
      background-position: center;
      background-repeat: no-repeat;
      @media screen and (max-width: $xs) {
        height: 33.5rem;
      }

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba($color: $lightBlack, $alpha: 0.45);
        z-index: 1;
      }
      &::after {
        content: "";
        bottom: -1px;
        position: absolute;
        width: 100%;
        height: 50%;
        background: linear-gradient(
          0deg,
          rgba($color: $lightBlack, $alpha: 1) 0%,
          rgba($color: $lightBlack, $alpha: 0) 100%
        );
        z-index: 1;
      }
    }
  }

  &__wrapper {
    position: relative;
    min-height: 100vh;
    min-width: 100vw;
  }

  &__main-container {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 36.2rem 10.5rem 30rem;
    z-index: 2;
    @media screen and (max-width: $xs) {
      padding: 33.5rem 2.6rem 30rem;
    }
  }

  &__details-box {
    display: flex;
    flex-direction: column;
    width: 70%;
    @media screen and (max-width: $md) {
      width: 100%;
    }

    &_tags {
      display: flex;
      margin: 0 0 15px -10px;
    }

    &_title {
      font-size: 40px;
      line-height: 1.25;
      @media screen and (max-width: $xs) {
        font-size: 35px;
      }
    }

    &_data {
      font-size: 16px;
      line-height: 1.56;
      margin: 12px 0rem 135px;
      text-transform: uppercase;
      @media screen and (max-width: $xs) {
        font-size: 14px;
        margin: 7rem 0rem 2.8rem;
      }
    }

    .button {
      margin-top: 50px;
      min-width: 160px;
    }
  }

  &__title-box {
    width: 100%;
    margin: 20.2rem 0rem 6.5rem;
    @media screen and (max-width: $xs) {
      margin: 11rem 0rem 4rem;
    }

    &_title {
      font-size: 40px;
      line-height: 1.25;
      @media screen and (max-width: $xs) {
        font-size: 35px;
      }
    }
  }

  &__press-releases-box {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, calc(33% - 2rem));
    grid-column-gap: 4rem;
    grid-row-gap: 9rem;
    @media screen and (max-width: $md) {
      grid-template-columns: repeat(2, calc(50% - 2rem));
    }
    @media screen and (max-width: $xs) {
      grid-template-columns: 100%;
      grid-column-gap: 0rem;
      grid-row-gap: 9rem;
    }

    &_card {
      display: flex;
      flex-direction: column;
      cursor: pointer;

      .image {
        position: relative;
        height: 27vw;
        width: 100%;
        background-size: cover;
        background-position: center;
        border-radius: 4px;
        @media screen and (max-width: $xs) {
          height: 90vw;
        }

        &__preview {
          position: absolute;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-self: center;
          opacity: 0;
          border-radius: 4px;
          transition: all 0.3s ease;

          p {
            width: 100%;
            text-align: center;
            font-size: 3rem;
            line-height: 1.67;
            letter-spacing: -1px;
          }
        }

        &:hover {
          .image__preview {
            opacity: 1;
            background-color: rgba($color: $blue, $alpha: 0.83);
          }
        }
      }

      .type {
        display: flex;
        justify-content: center;
        align-content: center;
        text-transform: uppercase;
        border: 1px solid $white;
        border-radius: 4px;
        width: max-content;
        min-width: 9.7rem;
        margin-top: 26px;
        font-size: 14px;
        line-height: 1.8;
        padding: 0.1rem 1rem;
      }

      .title {
        font-size: 24px;
        line-height: 1.3;
        margin-top: 10px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-clamp: 2;
        text-overflow: ellipsis;
        overflow: hidden;
        // &:hover {
        //   text-decoration: underline;
        // }
      }

      .data {
        margin-top: 12px;
        text-transform: uppercase;
        @media screen and (max-width: $xs) {
          margin-top: 10px;
          font-size: 14px;
          line-height: 1.8rem;
        }
      }
    }
  }
}
</style>
